$dark: #282c34;
$color: hotpink;

body {
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

ion-icon {
  color: $color;
}

.app {
  text-align: center;
}

.app-header {
  /*background-image: url('');
  background-size: 100% auto;
  background-position: center bottom -100px;*/
  background-color: $dark;
  height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 20px 0;
  ion-icon {
    font-size: 100px;
    margin-right: 30px;
  }
}

.app-link {
  color: $color;
}

.gallery {
  width: 100%;
  margin: 1px auto 20px auto;
}

.breadcrumb-container {
  background-color: #e7e3e5;
  padding: 20px 0;
  .breadcrumb {
    text-align: left;
    width: 90%;
    margin: 0 auto;
    .breadcrumb-item {
      display: inline-block;
      button {
        cursor: pointer;
        border: none;
        font-size: 15px;
        background: none;
        &:hover {
          color: $color;
          transition: color 300ms;
        }
        &:focus {
          outline: none;
        }

        @media (max-width: 767px) {
          font-size: 13px;
        }
      }
      &.breadcrumb-image-name {
        button {
          cursor: default;
          &:hover {
            color: $dark;
          }
        }
      }
    }
  }
}

.list-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .loading-container {
    flex-basis: 100%;
  }

  .list-item {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    flex-direction: column;
    height: 20vw;
    border: 1px solid white;
    box-sizing: border-box;
    justify-content: center;
    padding: 7px;
    background-color: $dark;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 150% auto;
    color: white;
    text-shadow: 0px 0px 5px #000;
    cursor: pointer;
    &:hover {
      opacity: 0.85;
      transition: opacity 300ms;
    }

    .folder-item-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 18px;
      ion-icon {
        font-size: 100px;
      }
    }

    @media (max-width: 767px) {
      flex-basis: 33.3333%;
      height: 30vw;
      .folder-item-content {
        font-size: 14px;
        line-height: 1.2;
        ion-icon {
          font-size: 32px;
        }
      }
    }
  }
}

.selected-image {
  img {
    max-width: 100%;
    &.hidden {
      display: none;
    }
  }
}

.loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-top: 20px;
  border: 5px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: $color;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}